import * as React from "react";
import { FunctionComponent, useContext, useState, useRef } from "react";
import PrmService, { ModifyPrmHeaders } from "../../services/PrmService";
import ExtendedStoreService from "../../services/ExtendedStoreService";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { Button, Col, Row } from "react-bootstrap";
import "./styles.scss";
import CognitoService from "../../services/CognitoService";
import { AuthContext } from "../../services/AuthContextService";
import PasswordStrengthMeter from "../PasswordStrengthMeter";
import { isValidPassword } from "../../utils/isValidPassword";
import Topics from "../Topics";
import { isValidEmail } from "../../utils/isValidEmail";
import { navigate } from "gatsby";
import {
  PortableText,
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface,
  SanityPasswordStrengthLabelsInterface,
  Slug
} from "../../types/SanityTypes";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event59 } from "web-common/src/analytics/event59";
import { IconError } from "web-common/src/images/icons/iconError";
import { RichTextSerializers } from "web-common/src/utils/richTextSerializers";

export type SignUpFormInterface = {
  sanitySignUp: {
    interestTopics: string;
    profileName: string;
    moreInfoExample: string;
    moreInfo: string;
    _rawPrivacyCookies: PortableText;
    _rawOffersUnilever: PortableText;
    _rawTermsOfUse: PortableText;
    verificationRequest: string;
    checkEmail: string;
  };
  sanityProfile: Slug;
  topics?: string[];
} & SanityCtaLabelsInterface &
  SanityFormsLabelsInterface &
  SanityErrorMessagesLabelsInterface &
  SanityPasswordStrengthLabelsInterface;

export const SignUpForm: FunctionComponent<SignUpFormInterface> = ({
  sanitySignUp,
  sanityProfile,
  topics,
  ctaLabels,
  formsLabels,
  errorMessages,
  passwordStrength
}) => {
  const signUp = sanitySignUp;
  const profile = sanityProfile;
  const { language } = useContext(LocalizedContext);
  const { prmApiKey, prmApiUrl, prmBrandCode, prmCampaignId, isoLanguage, isoCountry, extendedProfileApiUrl } =
    useSiteMetadata(language);

  const prmHeaders = {
    isoLanguage: isoLanguage,
    isoCountry: isoCountry,
    brandCode: prmBrandCode,
    campaignId: prmCampaignId
  };

  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [profilePicture, setProfilePicture] = useState<File | undefined>(undefined);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postCode, setPostCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedTopics, setSelectedTopics] = useState(new Set<string>());
  const [displayName, setDisplayName] = useState("");
  const [workoutMotivation, setWorkoutMotivation] = useState("");
  const [otherCommunicationsOptIn, setOtherCommunicationsOptIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayConfirmationInput, setDisplayConfirmationInput] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationMessage, setErrorMessage] = useState("");
  const { setAuthenticated, setToken, setTokenExpiryTimestamp } = useContext(AuthContext);
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const state = {
    profilePictureFile: profilePicture,
    profilePicture: profilePictureUrl,
    firstName,
    lastName,
    postCode,
    email,
    password,
    selectedTopics,
    displayName,
    workoutMotivation,
    otherCommunicationsOptIn,
    submitted
  };

  const handleInputChange = (
    set: (value: string) => void,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(event.target.value);
  };

  const handleProfilePictureChange = (event: React.FormEvent<HTMLInputElement>) => {
    setProfilePictureUrl(URL.createObjectURL((event.currentTarget.files as any)?.[0]));
    setProfilePicture(event.currentTarget.files?.[0]);
  };

  const handleTopicSelect = (topic: string) => {
    setSelectedTopics(selectedTopics => {
      state.selectedTopics.has(topic) ? state.selectedTopics.delete(topic) : state.selectedTopics.add(topic);
      return new Set(selectedTopics);
    });
  };

  const handleCheckboxChange = (set: (value: boolean) => void, event: React.ChangeEvent<HTMLInputElement>) => {
    set(event.target.checked);
  };
  return (
    <form className="sign-up-form" onSubmit={event => handleSubmit(event)} ref={formRef} noValidate>
      <Row>
        <Col lg={3}>
          <div className="profile-picture">
            <label htmlFor="profile-picture-upload">
              <input id="profile-picture-upload" data-testid="profile-picture-upload" type="file" onChange={handleProfilePictureChange} />
            </label>
            {profilePictureUrl && (
              <img
                data-testid="profile-picture-preview"
                alt="profile picture preview"
                src={state.profilePicture}
                width={"300"}
              />
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="questions-container">
            <div>
              <label htmlFor="first-name" className={`${submitted && (firstName ? 'valid' : 'invalid')}`}>
                <input
                  id="first-name"
                  data-testid="first-name"
                  className="input-line"
                  type="text"
                  placeholder={formsLabels?.firstName}
                  onChange={event => handleInputChange(setFirstName, event)}
                  required
                  aria-required="true"
                />
                *
                {submitted && !firstName && <p className="error" aria-live="assertive"><IconError />{errorMessages?.validFirstName}</p>}
              </label>
            </div>
            <div>
              <label htmlFor="last-name" className={`${submitted && (lastName ? 'valid' : 'invalid')}`}>
                <input
                  id="last-name"
                  data-testid="last-name"
                  className="input-line"
                  type="text"
                  placeholder={formsLabels?.lastName}
                  onChange={event => handleInputChange(setLastName, event)}
                  required
                  aria-required="true"
                />
                *
                {submitted && !lastName && <p className="error" aria-live="assertive"><IconError />{errorMessages?.validLastName}</p>}
              </label>
            </div>
            <div>
              <label htmlFor="postcode">
                <input
                  id="postcode"
                  data-testid="postcode"
                  className="input-line"
                  type="text"
                  placeholder={formsLabels?.postcode}
                  maxLength={4}
                  onChange={event => handleInputChange(setPostCode, event)}
                  aria-required="false"
                />
              </label>
            </div>
            <div>
              <label htmlFor="email" className={`${(email || submitted) && (isValidEmail(email) ? 'valid' : 'invalid')}`}>
                <input
                  id="email"
                  data-testid="email"
                  className="input-line"
                  type="email"
                  placeholder={formsLabels?.email}
                  onChange={event => handleInputChange(setEmail, event)}
                  required
                  aria-required="true"
                />
                *
                {((email || submitted) && !isValidEmail(email)) && <p className="error" aria-live="assertive"><IconError />{errorMessages?.validEmail}</p>}
              </label>
            </div>
            <div>
              <label htmlFor="password" className={`${(password || submitted) && (isValidPassword(password) ? 'valid' : 'invalid')}`}>
                <input
                  id="password"
                  data-testid="password"
                  className="input-line"
                  type="password"
                  placeholder={formsLabels?.password}
                  onChange={event => handleInputChange(setPassword, event)}
                  required
                  aria-required="true"
                />
                *
                {((password || submitted) && !isValidPassword(password)) && <p className="error" aria-live="assertive"><IconError />{errorMessages?.passwordRequirements}</p>}
              </label>
              <PasswordStrengthMeter password={state.password} passwordStrength={passwordStrength} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="topic-prefs">
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <p className="subhead">{signUp.interestTopics}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="btn-cluster">
                  <Topics handleTopicSelect={handleTopicSelect} selectedTopics={selectedTopics} topicData={topics} />
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <div className="profile-name">
            <p className="subhead">{signUp.profileName}</p>
            <label htmlFor="display-name" className={`${submitted && (displayName ? 'valid' : 'invalid')}`}>
              <input
                id="display-name"
                data-testid="display-name"
                className="input-line"
                type="text"
                placeholder={formsLabels?.profileName}
                onChange={event => handleInputChange(setDisplayName, event)}
                required
                aria-required="true"
              />
              *
              {(submitted && !displayName) && <p className="error" aria-live="assertive"><IconError />{errorMessages?.validDisplayName}</p>}
            </label>
          </div>

          <div className="profile-textarea">
            <p className="subhead">{signUp.moreInfo}</p>
            <label htmlFor="workout-motivation">
              <textarea
                id="workout-motivation"
                data-testid="workout-motivation"
                placeholder={signUp.moreInfoExample}
                autoComplete={"off"}
                rows={4}
                onChange={event => handleInputChange(setWorkoutMotivation, event)}
              />
            </label>
          </div>

          <div className="t-and-c">
            <div className="checkbox">
              <input
                data-testid="other-communications-opt-in"
                type="checkbox"
                onChange={event => handleCheckboxChange(setOtherCommunicationsOptIn, event)}
                id="checkbox_2"
              />
              <label htmlFor="checkbox_2">
                <BlockContent blocks={signUp._rawOffersUnilever} serializers={RichTextSerializers()}/>
              </label>
            </div>
            <div className="checkbox">
              <div className="privacy-and-cookies-notice">
                <BlockContent blocks={signUp._rawPrivacyCookies} serializers={RichTextSerializers()}/>
              </div>
              <div className="terms-of-use-notice">
                <BlockContent blocks={signUp._rawTermsOfUse} serializers={RichTextSerializers()}/>
              </div>
            </div>
          </div>
          <div className="submit-section">
            <Button type="submit" variant="outline-light" className="submit-button">
              {ctaLabels?.createAccount}
            </Button>
            {isLoading && <p>{formsLabels?.loading}</p>}
            {displayConfirmationInput && (
              <div className="verify-section">
                <input
                  data-testid="confirm-sign-up"
                  className="input-line"
                  type="text"
                  placeholder={signUp.verificationRequest}
                  onChange={event => handleInputChange(setVerificationCode, event)}
                />
                <label htmlFor="check-email">
                  <p id="check-email" aria-live="assertive">{signUp.checkEmail}</p>
                </label>
                <Button
                  type="button"
                  variant="outline-light"
                  className="submit-button"
                  disabled={!verificationCode}
                  onClick={() => handleConfirm(verificationCode, prmApiKey, prmApiUrl, prmHeaders)}
                >
                  {ctaLabels?.verify}
                </Button>
              </div>
            )}
            {confirmationMessage && (
              <p className="confirmation-message" aria-live="assertive">
                {confirmationMessage}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </form>
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setIsLoading(true);
    event.preventDefault();
    try {
      await setSubmitted(true);

      const isFormValid = formRef.current?.checkValidity() && isValidEmail(email) && isValidPassword(password);

      if (!isFormValid) {
        const firstInvalidField = window?.document.querySelector('.sign-up-form label.invalid');

        setIsLoading(false);
        setErrorMessage(errorMessages?.validForm as string);

        firstInvalidField?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        firstInvalidField?.querySelector('input')?.focus();
      } else {
        setErrorMessage("");
        await CognitoService.signUp(state.email, state.password);

        setIsLoading(false);
        setDisplayConfirmationInput(true);
      }
    } catch (error) {
      setIsLoading(false);
      if ((error as any).code === "UsernameExistsException") {
        try {
          await CognitoService.signIn(state.email, state.password);
          setErrorMessage(errorMessages?.duplicateEmail as string);
          await CognitoService.signOut();
        } catch (error) {
          if ((error as any).code === "UserNotConfirmedException") {
            setDisplayConfirmationInput(true);
            await CognitoService.resendEmailVerificationCode(state.email);
          } else {
            setErrorMessage(errorMessages?.signUpError as string);
          }
        }
      } else {
        setErrorMessage(errorMessages?.signUpError as string);
      }
    }
  }

  async function handleConfirm(
    verificationCode: string,
    prmApiKey: string,
    prmApiUrl: string,
    prmHeaders: ModifyPrmHeaders
  ) {
    setIsLoading(true);
    try {
      await CognitoService.confirmSignUp(state.email, verificationCode);
      const cognitoUser = await CognitoService.signIn(state.email, state.password);
      const token = CognitoService.getToken(cognitoUser);

      try {
        const response = await PrmService.createPrmProfile(prmApiKey, prmApiUrl, prmHeaders, {
          firstName: state.firstName,
          lastName: state.lastName,
          postCode: state.postCode,
          email: state.email,
          displayName: state.displayName,
          otherCommunicationsOptIn: state.otherCommunicationsOptIn,
          termsAndConditionsAgreed: true
        });

        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);
        const { UnileverId } = response;
        document.cookie = `UnileverId=${UnileverId}; expires=${expireDate.toUTCString()} `;
        event59();

        await ExtendedStoreService.createUpdateExtendedProfile(token, extendedProfileApiUrl, {
          workoutMotivation: state.workoutMotivation,
          topics: Array.from(state.selectedTopics) as string[],
          profilePicture: state.profilePictureFile || null
        });

        setAuthenticated(true);
        setToken(token);
        setTokenExpiryTimestamp(CognitoService.getTokenExpiryTimestamp(cognitoUser));

        navigate(`/${profile.slug.current}/`);
      } catch (error) {
        await CognitoService.deleteUser();
        setErrorMessage(errorMessages?.signUpError as string);
        setDisplayConfirmationInput(false);
        setIsLoading(false);
      }
    } catch (error) {
      if ((error as any)?.code === "CodeMismatchException") {
        setErrorMessage(errorMessages?.verificationIncorrect as string);
      } else {
        setErrorMessage(errorMessages?.signUpError as string);
        setDisplayConfirmationInput(false);
      }
      setIsLoading(false);
    }
  }
};

export default SignUpForm;
