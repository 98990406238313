import React from "react";

export const IconError = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Error" transform="translate(-6984 143)">
        <rect id="Rectangle_16" data-name="Rectangle 16" width="24" height="24" transform="translate(6984 -143)" fill="none"/>
        <path id="error_FILL0_wght400_GRAD0_opsz48" d="M12,16a.649.649,0,1,0-.47-.19A.638.638,0,0,0,12,16Zm-.54-3.06h1.2V7.88h-1.2ZM12,20a7.741,7.741,0,0,1-3.1-.63A8.06,8.06,0,0,1,4.63,15.1a7.991,7.991,0,0,1,0-6.22A8,8,0,0,1,6.35,6.34,8.2,8.2,0,0,1,8.9,4.63a7.991,7.991,0,0,1,6.22,0,7.988,7.988,0,0,1,4.25,4.25,7.991,7.991,0,0,1,0,6.22,8.2,8.2,0,0,1-1.71,2.55,8,8,0,0,1-2.54,1.72A7.79,7.79,0,0,1,12,20Zm.02-1.2a6.52,6.52,0,0,0,4.8-1.99,6.593,6.593,0,0,0,1.98-4.83,6.537,6.537,0,0,0-1.98-4.8A6.56,6.56,0,0,0,12,5.2,6.57,6.57,0,0,0,7.19,7.18,6.543,6.543,0,0,0,5.2,12a6.553,6.553,0,0,0,1.99,4.81A6.576,6.576,0,0,0,12.02,18.8ZM12,12Z" transform="translate(6984 -143)" fill="#eb3323"/>
      </g>
    </svg>
  );
};
