import * as React from "react";
import { FunctionComponent } from "react";

export interface TopicsInterface {
  topicData?: string[];
  handleTopicSelect: (topic: string) => void;
  selectedTopics: Set<string>;
}

const Topics: FunctionComponent<TopicsInterface> = ({ handleTopicSelect, selectedTopics, topicData }) => {
  return (
    <>
      {topicData?.map((topicOption: string, index: number) => {
        return (
          <li className="checkbox-chip" key={index}>
            <input
              id={`topic-${index}`}
              data-testid={`topic-${index}`}
              type="checkbox"
              onChange={() => handleTopicSelect(topicOption)}
              checked={selectedTopics.has(topicOption)}
            />
            <label htmlFor={`topic-${index}`}>{topicOption}</label>
          </li>
        );
      })}
    </>
  );
};

export default Topics;
