import * as React from "react";
import SignUpForm from "../../components/SignUpForm";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, PageProps } from "gatsby";
import { LocalizedContextInterface, InternalLink, PortableText, MultiLineHeadline, Seo } from "../../types/SanityTypes";
import "./styles.scss";
import { LocalisedPageContext } from "../../types/PageTypes";

type SignUpProps = {
  signUp: {
    headline: MultiLineHeadline;
    interestTopics: string;
    profileName: string;
    moreInfoExample: string;
    moreInfo: string;
    _rawPrivacyCookies: PortableText;
    _rawOffersUnilever: PortableText;
    _rawOffersBrand: PortableText;
    _rawTermsOfUse: PortableText;
    verificationRequest: string;
    checkEmail: string;
    slug: {
      current: string;
    };
    seo: Seo;
  };
  sanityProfile: InternalLink;
} & LocalizedContextInterface;

type SignUpPageContext = LocalisedPageContext;

export const query = graphql`
  query SignUpPage($_id: String, $language: String, $market: String) {
    signUp: sanitySignUp(_id: { eq: $_id }) {
      headline {
        primaryText
        secondaryText
        tertiaryText
      }
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      interestTopics
      profileName
      moreInfoExample
      moreInfo
      _rawPrivacyCookies(resolveReferences: { maxDepth: 8 })
      _rawOffersUnilever(resolveReferences: { maxDepth: 8 })
      _rawOffersBrand(resolveReferences: { maxDepth: 8 })
      _rawTermsOfUse(resolveReferences: { maxDepth: 8 })
      verificationRequest
      checkEmail
    }
    sanityProfile(i18n_lang: { eq: $language }, market: { eq: $market }) {
      _id
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const SignUp = (props: PageProps<SignUpProps, SignUpPageContext>) => {
  const signUpPage = props.data.signUp;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={signUpPage.seo.metaTitle}
      pageType={"signUp"}
    >
      <StructuredData customData={signUpPage.seo.jsonld} />
      <SEO title={signUpPage.seo.metaTitle} description={signUpPage.seo.metaDescription} />
      <div className="page_sign-up">
        <Container fluid>
          <Row>
            <Col>
              <h1>
                <span className="heading-line-1">{signUpPage.headline.primaryText}</span>
                {signUpPage.headline.secondaryText && (
                  <span className="heading-line-2">{signUpPage.headline.secondaryText}</span>
                )}
                {signUpPage.headline.tertiaryText && (
                  <span className="heading-line-1">{signUpPage.headline.tertiaryText}</span>
                )}
              </h1>
              <SignUpForm
                sanitySignUp={signUpPage}
                sanityProfile={props.data.sanityProfile}
                ctaLabels={props.data.sanityLabels?.ctaLabels}
                formsLabels={props.data.sanityLabels?.formsLabels}
                errorMessages={props.data.sanityLabels?.errorMessages}
                topics={props.data.sanityLabels?.topics}
                passwordStrength={props.data.sanityLabels?.passwordStrength}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default SignUp;
