import { Auth, CognitoUser } from "@aws-amplify/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";
export default {
  signUp: async (email: string, password: string) => {
    return await Auth.signUp({
      username: email,
      password: password
    });
  },
  confirmSignUp: async (email: string, confirmationCode: string) => {
    return await Auth.confirmSignUp(email, confirmationCode);
  },

  resendEmailVerificationCode: async (email: string) => {
    return await Auth.resendSignUp(email);
  },

  signIn: async (email: string, password: string): Promise<CognitoUser> => {
    return await Auth.signIn(email, password);
  },

  signOut: async () => {
    return await Auth.signOut();
  },

  getActiveSession: async (): Promise<CognitoUserSession> => {
    return await Auth.currentSession();
  },

  forgotPassword: async (email: string) => {
    return await Auth.forgotPassword(email);
  },

  forgotPasswordVerification: async (email: string, code: string, password: string) => {
    return await Auth.forgotPasswordSubmit(email, code, password);
  },

  /**
   * User must be signed in.
   */
  deleteUser: async () => {
    await Auth.currentAuthenticatedUser().then(user =>
      user.deleteUser((error: Error) => {
        if (error) {
          throw error;
        }
      })
    );
  },

  /**
   * User must be signed in.
   * @param cognitoUser
   */
  getToken: (cognitoUser: CognitoUser): string => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return cognitoUser.getSignInUserSession()!.getIdToken().getJwtToken();
  },

  /**
   * User must be signed in.
   * @param cognitoUser
   */
  getTokenExpiryTimestamp: (cognitoUser: CognitoUser): number => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return cognitoUser.getSignInUserSession()!.getIdToken().getExpiration();
  }
};
