export default {
  createUpdateExtendedProfile: async (authToken: string, url: string, payload: ExtendedProfileUserData) => {
    const headers = { Authorization: `Bearer ${authToken}` };

    const profileBody = {
      profile: {
        workoutMotivation: payload.workoutMotivation,
        topics: payload.topics
      }
    };

    if (payload.profilePicture) {
      const signedUrl = await executeRequest("get", url + "/picture/signedUrl?contentType=image/png", {
        Authorization: `Bearer ${authToken}`
      });

      await fetch(signedUrl.data.url, {
        method: "put",
        headers: ({ Accept: "*/*", "Content-Type": "image/png" } as unknown) as Headers,
        body: payload.profilePicture
      }).then(handleError);

      return executeRequest("post", url + "/data/store", headers, {
        ...profileBody,
        imageHashKey: signedUrl.data.uniqueKey
      });
    } else {
      return executeRequest("post", url + "/data/store", headers, profileBody);
    }
  },

  getExtendedProfile: (authToken: string, url: string) => {
    const headers = { Authorization: `Bearer ${authToken}` };

    return executeRequest("get", url + "/data", headers);
  }
};

const executeRequest = <T, U>(method: string, url: string, headers?: T, body?: U) => {
  return fetch(url, {
    method: method,
    headers: ({ Accept: "*/*", "Content-Type": "application/json", ...headers } as unknown) as Headers,
    body: JSON.stringify(body)
  })
    .then(handleError)
    .then(res => res.json());
};

const handleError = (response: Response) => {
  if (!response.ok) throw new Error(response.status.toString());
  return response;
};

interface ExtendedProfileUserData {
  workoutMotivation: string;
  topics: string[];
  profilePicture: File | null;
}
