import { EventType, pushEvent } from "./index";

/**
 * Sign Up success
 */


export const event59 = () => {
  // @ts-ignore
  window.digitalData.newsletterSignUp = [];
  // @ts-ignore
  window.digitalData.newsletterSignUp.push({
    componentInfo: {
      componentID: "NewsletterSignUpForm"
    }
  });
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.SignUpSubmit,
      eventLabel: "Sign Up|Form Submit|Newsletter Sign Up",
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Lead"
  });
};
