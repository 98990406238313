import React from "react";
import { FunctionComponent } from "react";
import "./styles.scss";
import {
  hasLowerCaseCharacter,
  hasUpperCaseCharacter,
  hasMinimumLength,
  hasOneNumber,
  hasSpecialCharacter
} from "../../utils/isValidPassword";
import { SanityPasswordStrengthLabelsInterface } from "../../types/SanityTypes";

const activeClass = "active";

export type PasswordStrengthMeterInterface = {
  password: string;
} & SanityPasswordStrengthLabelsInterface;

export const PasswordStrengthMeter: FunctionComponent<PasswordStrengthMeterInterface> = ({
  password,
  passwordStrength
}) => {
  return (
    <ul className="password-strength-meter-list">
      <li className={hasLowerCaseCharacter(password) ? activeClass : ""} aria-live="assertive">
        {passwordStrength?.lowercase}
      </li>
      <li className={hasSpecialCharacter(password) ? activeClass : ""} aria-live="assertive">
        {passwordStrength?.special}
      </li>
      <li className={hasUpperCaseCharacter(password) ? activeClass : ""} aria-live="assertive">
        {passwordStrength?.uppercase}
      </li>
      <li className={hasMinimumLength(password) ? activeClass : ""} aria-live="assertive">
        {passwordStrength?.eightMinimum}
      </li>
      <li className={hasOneNumber(password) ? activeClass : ""} aria-live="assertive">
        {passwordStrength?.number}
      </li>
    </ul>
  );
};

export default PasswordStrengthMeter;
