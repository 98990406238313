export const isValidPassword = (password: string) => {
  return (
    hasLowerCaseCharacter(password) &&
    hasSpecialCharacter(password) &&
    hasUpperCaseCharacter(password) &&
    hasMinimumLength(password) &&
    hasOneNumber(password)
  );
};

export const hasLowerCaseCharacter = (password: string) => {
  return /(?=.*[a-z])/.test(password);
};

export const hasSpecialCharacter = (password: string) => {
  return /(?=.*[!@#$%^&*-])/.test(password);
};

export const hasUpperCaseCharacter = (password: string) => {
  return /(?=.*[A-Z])/.test(password);
};

export const hasMinimumLength = (password: string) => {
  return /(?=.{8,})/.test(password);
};

export const hasOneNumber = (password: string) => {
  return /(?=.*[0-9])/.test(password);
};
