export default {
  createPrmProfile: (apiKey: string, url: string, prmHeaders: ModifyPrmHeaders, userData: PrmUserData) => {
    const headers = { "x-api-key": apiKey };

    return createUpdatePrmProfile(url, headers, prmHeaders, userData);
  },

  updatePrmProfile: (
    apiKey: string,
    authToken: string,
    url: string,
    prmHeaders: ModifyPrmHeaders,
    userData: PrmUserData
  ) => {
    const headers = {
      "x-api-key": apiKey,
      Authorization: `Bearer ${authToken}`
    };

    return createUpdatePrmProfile(url + "/profile/update", headers, prmHeaders, userData);
  },

  getPrmProfile: (apiKey: string, authToken: string, url: string, prmHeaders: ReadPrmHeaders) => {
    const headers = {
      "x-api-key": apiKey,
      Authorization: `Bearer ${authToken}`
    };

    const body = {
      profile: {
        header: {
          // isoLanguage must be provided, but it is not used by PRM when querying for a user's profile.
          // We hard-code it here so that we don't have to get the value from site metadata whenever we want to read
          // a profile.
          isoLanguage: "AA",
          ...prmHeaders
        }
      }
    };

    return executeRequest("post", url + "/profile/retrieve", headers, body);
  }
};

const createUpdatePrmProfile = <T>(url: string, headers: T, prmHeaders: ModifyPrmHeaders, userData: PrmUserData) => {
  const body = {
    profile: {
      header: {
        ...prmHeaders,
        origin: "Website",
        formType: "signUp",
        entity: "PRM2.6"
      },
      consumerIdentity: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        preferredName: userData.displayName,
        dateOfBirth: userData.dateOfBirth
      },
      contactDetail: {
        email: userData.email,
        address: {
          postalCode: userData.postCode
        }
      },
      optInStatus: {
        brandEmailConsent: userData.sureCommunicationsOptIn ? true : undefined,
        unileverEmailConsent: userData.otherCommunicationsOptIn ? true : undefined,
        legalAgeConsent: userData.termsAndConditionsAgreed
      },
      questionAndAnswers: userData.questionAndAnswers
    }
  };

  return executeRequest("post", url, headers, body);
};

const executeRequest = <T, U>(method: string, url: string, headers?: T, body?: U) => {
  return fetch(url, {
    method: method,
    headers: ({ Accept: "*/*", "Content-Type": "application/json", ...headers } as unknown) as Headers,
    body: JSON.stringify(body)
  })
    .then(handleError)
    .then(res => res.json());
};

const handleError = (response: Response) => {
  if (!response.ok) throw new Error(response.status.toString());
  return response;
};

export interface ReadPrmHeaders {
  isoCountry: string;
  brandCode: string;
  campaignId?: string;
}

export interface ModifyPrmHeaders extends ReadPrmHeaders {
  isoLanguage: string;
}

export interface AnswerAndQuestion {
  questionId: number,
  answerId: number[],
}

export interface PrmUserData {
  firstName?: string;
  lastName?: string;
  postCode?: string;
  email?: string;
  displayName?: string;
  dateOfBirth?: string;
  gender?: string;
  sureCommunicationsOptIn?: boolean;
  otherCommunicationsOptIn?: boolean;
  termsAndConditionsAgreed?: boolean;
  questionAndAnswers?: Array<AnswerAndQuestion>;
}
