import { createContext } from "react";

export interface AuthContextInterface {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  tokenExpiryTimestamp: number; // seconds since Epoch
  setTokenExpiryTimestamp: (tokenExpiryTimestamp: number) => void;
  token: string;
  setToken: (token: string) => void;
}

/**
 * Each (${value}, set${Value}) pair is intended to be replaced by a React hook and passed to a Provider.
 */
const defaultAuthContext: AuthContextInterface = {
  authenticated: false,
  setAuthenticated: () => {
    return;
  }, // Noop
  tokenExpiryTimestamp: 0,
  setTokenExpiryTimestamp: () => {
    return;
  }, // Noop
  token: "",
  setToken: () => {
    return;
  } // Noop
};

export const AuthContext = createContext<AuthContextInterface>(defaultAuthContext);
